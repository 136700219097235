import { SVGProps } from "react";

export const Validation = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 30 26"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="View-:-admin" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Artboard"
          transform="translate(-295.000000, -39.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <g id="reminfo/icons/validation" transform="translate(295.000000, 39.489855)">
            <g
              id="4879883_achievement_check_mark_ok_success_icon"
              transform="translate(0.000000, -0.000000)"
            >
              <path
                d="M9.8,24.5933333 L0.3,13.8266667 C-0.1,13.3733333 -0.1,12.58 0.3,12.0133333 L1.8,10.3133333 C2.2,9.86 2.9,9.86 3.4,10.3133333 L10.6,18.4733333 L26.6,0.34 C27,-0.113333333 27.7,-0.113333333 28.2,0.34 L29.7,2.04 C30.1,2.49333333 30.1,3.28666667 29.7,3.85333333 L11.4,24.5933333 C10.9,25.0466667 10.2,25.0466667 9.8,24.5933333 Z"
                id="check_x5F_mark_1_"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
