import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { routes } from "engine/routes";
import { StyledMenu } from "./styled";

export const Menu = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <StyledMenu
      mode="horizontal"
      selectedKeys={[pathname.split("/")[2]]}
      items={[
        {
          key: "articles",
          label: <Link to={routes.dashboard.articles().overview}>{t("dashboard.articles")}</Link>,
        },
        {
          key: "tags",
          label: <Link to={routes.dashboard.tags().overview}>{t("dashboard.tags")}</Link>,
        },
      ]}
    />
  );
};
