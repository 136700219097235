import { SVGProps } from "react";

export const Education = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="SL/icons/icon/education" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="2205242_college_course_degree_education_university_icon"
          transform="translate(-1.000000, 2.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <polygon
            id="Path"
            points="0 7.69230769 15.5 15.3846154 29.0625 8.65384615 29.0625 25 31 25 31 7.69230769 15.5 0"
          />
          <polygon
            id="Path"
            points="6 12 6 20.3176776 15.5 25 25 20.3176776 25 12 15.5 16.6823224"
          />
        </g>
      </g>
    </svg>
  );
};
