import { createSlice } from "@reduxjs/toolkit";
import { getTags, getTagsByCategory, getTagById, addTag, editTag, deleteTag } from "./actions";
import { ITag, ILoading } from "./types";

interface ITagsState {
  loading: ILoading;
  tags: ITag[];
  selectedTag?: ITag;
}

const initialState: ITagsState = {
  loading: {
    getTags: false,
    getTagById: false,
    addTag: false,
    editTag: false,
    deleteTag: false,
    getTagsByCategory: false,
  },
  tags: [],
};

export const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    clearTagState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getTags.pending, (state) => {
      state.loading.getTags = true;
    });
    builder.addCase(getTags.rejected, (state) => {
      state.loading.getTags = false;
    });
    builder.addCase(getTags.fulfilled, (state, action) => {
      state.loading.getTags = false;
      state.tags = action.payload;
    });
    builder.addCase(getTagsByCategory.pending, (state) => {
      state.loading.getTagsByCategory = true;
    });
    builder.addCase(getTagsByCategory.rejected, (state) => {
      state.loading.getTagsByCategory = false;
    });
    builder.addCase(getTagsByCategory.fulfilled, (state, action) => {
      state.loading.getTagsByCategory = false;
      state.tags = action.payload;
    });
    builder.addCase(getTagById.pending, (state) => {
      state.loading.getTagById = true;
    });
    builder.addCase(getTagById.rejected, (state) => {
      state.loading.getTagById = false;
    });
    builder.addCase(getTagById.fulfilled, (state, action) => {
      state.loading.getTagById = false;
      state.selectedTag = action.payload;
    });
    builder.addCase(addTag.pending, (state) => {
      state.loading.addTag = true;
    });
    builder.addCase(addTag.rejected, (state) => {
      state.loading.addTag = false;
    });
    builder.addCase(addTag.fulfilled, (state) => {
      state.loading.addTag = false;
    });
    builder.addCase(editTag.pending, (state) => {
      state.loading.editTag = true;
    });
    builder.addCase(editTag.rejected, (state) => {
      state.loading.editTag = false;
    });
    builder.addCase(editTag.fulfilled, (state) => {
      state.loading.editTag = false;
    });
    builder.addCase(deleteTag.pending, (state) => {
      state.loading.deleteTag = true;
    });
    builder.addCase(deleteTag.rejected, (state) => {
      state.loading.deleteTag = false;
    });
    builder.addCase(deleteTag.fulfilled, (state) => {
      state.loading.deleteTag = false;
    });
  },
});

export const { clearTagState } = tagsSlice.actions;
