import { defaultRequest } from "helpers";
import { IArticle } from "engine/store/articles/types";
import { IGetFilteredArticlesRequest } from "pages/articles/articles-overview/types";

const getArticles = () => defaultRequest.get<IArticle[]>("articles");

const getArticlesByIds = (ids: string[]) =>
  defaultRequest.post<IArticle[]>("articles/saved", { ids });

const getFilteredArticles = (data: IGetFilteredArticlesRequest) =>
  defaultRequest.post<IArticle[]>("articles/filter", data);

const getArticleById = (id: string) => defaultRequest.get<IArticle>(`articles/${id}`);

const addArticle = (data: IArticle) => defaultRequest.post("articles", data);

const editArticle = ({ _id, ...rest }: IArticle) => defaultRequest.put(`articles/${_id}`, rest);

const deleteArticle = (id: string) => defaultRequest.delete(`articles/${id}`);

export const articlesApi = {
  getArticles,
  getFilteredArticles,
  getArticlesByIds,
  getArticleById,
  addArticle,
  editArticle,
  deleteArticle,
};
