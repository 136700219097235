import { createSlice } from "@reduxjs/toolkit";
import { getMe, login } from "./actions";
import { IUser } from "./types";

interface IAuthState {
  loading: boolean;
  user: IUser | null;
}

const initialState: IAuthState = {
  loading: false,
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(getMe.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMe.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getMe.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    });
  },
});
