import { Navigate, Outlet } from "react-router-dom";
import { useLocalStorage } from "engine/hooks";
import { routes } from "engine/routes";
import { Footer, Header } from "./components";
import { StyledMainLayout } from "./styled";

export const MainLayout = () => {
  const [choosenLanguage] = useLocalStorage("choosenLanguage", "");

  return !choosenLanguage ? (
    <Navigate to={routes.main.languageDetection} />
  ) : (
    <StyledMainLayout>
      <Header />
      <div className="main-content">
        <Outlet />
      </div>
      <Footer />
    </StyledMainLayout>
  );
};
