import { defaultRequest } from "helpers";
import { ITag } from "engine/store/tags/types";

const getTags = () => defaultRequest.get<ITag[]>("tags");

const getTagById = (id: string) => defaultRequest.get<ITag>(`tags/${id}`);

const getTagsByCategory = (categoryName: string | null) =>
  defaultRequest.get<ITag[]>(`tags/category?categoryName=${categoryName}`);

const addTag = (data: ITag) => defaultRequest.post("tags", data);

const editTag = ({ _id, ...rest }: ITag) => defaultRequest.put(`tags/${_id}`, rest);

const deleteTag = (id: string) => defaultRequest.delete(`tags/${id}`);

export const tagsApi = {
  getTags,
  getTagById,
  getTagsByCategory,
  addTag,
  editTag,
  deleteTag,
};
