import styled from "@emotion/styled";
import { Drawer } from "antd";

export const StyledHeader = styled.header<{ direction: "ltr" | "rtl" }>`
  height: 50px;
  display: flex;
  padding: 0.25rem 3rem 0.3rem;
  box-shadow: 0 2px 4px 0 #a9a9a9;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.palette.custom.white};
  direction: ${({ direction }) => direction};

  .logo {
    ${({ direction }) => (direction === "rtl" ? "margin-left: 2rem" : "margin-right: 2rem")};

    a {
      display: block;

      img {
        height: 40px;
      }
    }
  }

  .ant-menu {
    width: 100%;
    line-height: 38px;
    border-bottom: 0;
    gap: 0.25rem;

    &-submenu-title {
      height: 100%;
    }

    &-submenu,
    &-item {
      padding: 0 0.25rem !important;

      &-selected {
        background-color: #e9f4fa;
      }

      &:after {
        content: none;
      }
    }
    &-submenu-active,
    &-item-active {
      background-color: #e9f4fa !important;
    }

    &-title-content {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0 0.5rem;
      height: 100%;

      svg {
        font-size: 0.625rem;
      }
    }
  }

  .right-menu {
    gap: 0.5rem;
    justify-content: flex-end;

    .saved-articles {
      display: flex;
      align-items: center;
      gap: 0.625rem;

      svg {
        height: 20px;
      }
    }

    .ant-menu-item {
      &:nth-of-type(2) {
        padding: 0 !important;

        .ant-menu-title-content {
          padding: 0 !important;

          .ant-select {
            height: 100%;

            &:hover {
              .ant-select-selector {
                border: 0;
              }
            }

            &-focused {
              .ant-select-selector {
                box-shadow: none;
              }
            }

            .ant-select-selector {
              height: 100%;
              align-items: center;
              background-color: ${({ theme }) => theme.palette.text.primary};
              color: ${({ theme }) => theme.palette.custom.white};
              border: 0;
              border-radius: 0.25rem;
            }

            .ant-select-selection-item {
              ${({ direction }) =>
                direction === "rtl" ? "padding-right: 0" : "padding-right: 0.75rem"};
              display: flex;
              gap: 0.5rem;
              align-items: center;

              svg {
                color: ${({ theme }) => theme.palette.primary.main};
              }
            }

            .ant-select-arrow {
              .anticon {
                ${({ direction }) => direction === "rtl" && "margin-left: 0"};
              }

              svg {
                color: ${({ theme }) => theme.palette.primary.main};
              }
            }
          }
        }

        .ant-select-item-option-content {
          svg {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    padding: 0.25rem 0.5rem;
    height: 40px;

    .logo {
      margin-right: 0;

      a {
        img {
          height: 32px;
        }
      }
    }

    .left-menu {
      display: none;
    }

    .right-menu {
      .ant-menu-item:first-of-type {
        display: none;
      }

      .ant-menu-item {
        .ant-menu-title-content {
          .ant-select {
            .ant-select-selector {
              padding: 0 0.5rem;
            }

            .ant-select-arrow {
              svg {
                height: 18px;
              }
            }
          }
        }
      }
    }

    .menu-icon {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      ${({ direction }) => (direction === "ltr" ? "margin-left: 1rem" : "margin-right: 1rem")};

      svg {
        height: 20px;
      }
    }
  }
`;

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 100% !important;

    .ant-drawer-header {
      padding: 0.25rem 1rem;
      margin-bottom: 2rem;
      border-bottom: 0;

      .ant-drawer-header-title {
        flex-direction: row-reverse;

        .logo {
          img {
            height: 40px;
          }
        }
      }

      .ant-drawer-close {
        font-size: 1.25rem;
        color: ${({ theme }) => theme.palette.custom.white};
        margin: 0;
      }
    }

    .ant-drawer-content {
      background-color: #020f21;

      .ant-drawer-body {
        padding: 0;
      }

      .ant-menu {
        background-color: #020f21;
        border: 0;

        .ant-menu-item {
          line-height: 1;
          height: auto;
          margin: 0;
          padding: 0 !important;
          width: 100%;

          &:after {
            content: none;
          }

          &-selected {
            background-color: #063069;
          }

          .ant-menu-title-content {
            display: block;

            a {
              display: flex;
              align-items: center;
              color: ${({ theme }) => theme.palette.custom.white};
              font-size: 1.125rem;
              line-height: 1;
              padding: 1rem 1.5rem;
              border-top: 1px solid ${({ theme }) => theme.palette.custom.white};
              gap: 1rem;

              .icon {
                width: 1.5rem;
                font-size: 1.25rem;
                color: ${({ theme }) => theme.palette.primary.main};
                margin-right: unset;
              }
            }

            .saved-articles {
              svg {
                height: 20px;
              }
            }
          }

          &:last-of-type {
            .ant-menu-title-content {
              a {
                border-bottom: 1px solid ${({ theme }) => theme.palette.custom.white};
              }
            }
          }
        }

        .ant-menu-submenu {
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: center;

          .ant-menu-submenu-title {
            margin: 0;
            height: auto;
            line-height: 1;
            width: 100%;
            padding: 1rem;
            background-color: #020f21;

            .ant-menu-title-content {
              font-size: 1.125rem;
              color: ${({ theme }) => theme.palette.custom.white};
              line-height: 1;
              display: flex;
              gap: 1rem;
              align-items: center;

              .icon {
                width: 1.5rem;
                font-size: 1.25rem;
                color: ${({ theme }) => theme.palette.primary.main};

                svg {
                  height: 20px;
                }
              }
            }

            .ant-menu-submenu-arrow {
              color: ${({ theme }) => theme.palette.primary.main};
            }
          }

          .ant-menu {
            width: 80%;

            .ant-menu-item {
              &:first-of-type {
                .ant-menu-title-content {
                  a {
                    padding-top: 0.5rem;
                    border-top: 0;
                  }
                }
              }
              &:last-of-type {
                .ant-menu-title-content {
                  a {
                    border-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
