import { SVGProps } from "react";

export const Search = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="27px"
      height="28px"
      viewBox="0 0 27 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        id="View-:-admin"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Artboard"
          transform="translate(-432.000000, -34.000000)"
          stroke="currentColor"
          strokeWidth="2"
        >
          <g id="iconfinder_search_2561381" transform="translate(433.000000, 35.956522)">
            <circle id="Oval" cx="10.4166667" cy="10.4166667" r="10.4166667" />
            <line x1="25" y1="25" x2="17.7777778" y2="17.7777778" id="Path" />
          </g>
        </g>
      </g>
    </svg>
  );
};
