import axios from "axios";

const axiosCancelTokenSource = axios.CancelToken.source();
const axiosIsCancel = axios.isCancel;

const defaultRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/`,
});

defaultRequest.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("admin_token");

    if (token) {
      /* eslint-disable no-param-reassign */
      config.headers = {
        authorization: `Token ${token}`,
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export { defaultRequest, axiosCancelTokenSource, axiosIsCancel };
