import { defaultRequest } from "helpers";
import { ILoginRequest, IUser } from "engine/store/auth/types";

const login = (requestData: ILoginRequest) => defaultRequest.post<IUser>("auth/login", requestData);

const me = () => defaultRequest.get<{ user: IUser }>("auth/me");

export const authApi = {
  login,
  me,
};
