import { ReactNode } from "react";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { light } from "./light";
import { CssBaseline } from "./css-baseline";

interface IThemeProvider {
  children: ReactNode;
}

export const ThemeProvider = ({ children }: IThemeProvider) => {
  const theme = light;

  return (
    <EmotionThemeProvider theme={theme}>
      <CssBaseline theme={theme} />
      {children}
    </EmotionThemeProvider>
  );
};
