import styled from "@emotion/styled";
import { Layout } from "antd";

export const StyledContent = styled(Layout.Content)`
  background-color: ${({ theme }) => theme.palette.background.default};
  min-height: calc(100vh - 50px);
  padding: 0 4rem 2rem;

  .ant-btn {
    font-size: 1rem;
    font-weight: 500;
    height: auto;
    border-radius: 0.375rem;
    padding: 0.5rem 1rem;
  }

  .ant-btn-link:not(.ant-btn-dangerous) {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  .ant-btn-icon-only {
    padding: 0;

    &:hover,
    &:focus {
      svg {
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }

  .ant-btn-primary {
    background-color: ${({ theme }) => theme.palette.primary.main};

    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  .ant-form-item-label {
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.875rem;
  }

  .ant-input,
  .ant-input-number {
    border-radius: 0.375rem;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    width: 100%;
    color: ${({ theme }) => theme.palette.text.primary};
    border: 1px solid ${({ theme }) => theme.palette.text.secondary};

    &:focus {
      box-shadow: none;
    }
  }

  .ant-picker {
    width: 100%;
    border-radius: 0.375rem;
    padding: 0.5rem 1rem;
    color: ${({ theme }) => theme.palette.text.primary};
    border: 1px solid ${({ theme }) => theme.palette.text.secondary};

    &-focused {
      box-shadow: none !important;
    }

    &-input {
      & > input {
        font-size: 1rem;
      }
    }
  }

  .article-detail,
  .tag-detail {
    .ant-select {
      line-height: unset;

      &-focused {
        .ant-select-selector {
          box-shadow: none !important;
        }
      }

      &-selector {
        border-radius: 0.375rem !important;
        font-size: 1rem;
        padding: 0.5rem 1rem !important;
        color: ${({ theme }) => theme.palette.text.primary};
        border: 1px solid ${({ theme }) => theme.palette.text.secondary} !important;
        height: auto !important;

        &:after {
          line-height: unset !important;
        }

        .ant-select-selection-item {
          line-height: unset;
        }
      }

      .ant-select-selection-placeholder {
        line-height: unset !important;
      }
    }
  }
`;
