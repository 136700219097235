import { SVGProps } from "react";

export const Accommodation = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} viewBox="0 0 64 64" fill="currentColor">
      <g>
        <path d="M13.068,36.785v19.236c0,0.483,0.392,0.876,0.876,0.876h20.645V39.479h9.698v17.418h5.769c0.485,0,0.876-0.393,0.876-0.876   V36.785l-18.921-18.84L13.068,36.785z M28.464,47.594h-8.751v-8.114h8.751V47.594z" />
        <path d="M54.767,29.868L32.001,7.103l-10.74,10.739v-4.726c0-1.104-0.896-2-2-2s-2,0.896-2,2v8.726l-8.027,8.026   c-0.977,0.977-0.977,2.56,0,3.536c0.488,0.488,1.128,0.732,1.768,0.732s1.279-0.244,1.768-0.731l19.232-19.23l19.23,19.229   c0.977,0.977,2.559,0.977,3.535,0C55.743,32.427,55.743,30.844,54.767,29.868z" />
      </g>
    </svg>
  );
};
