import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { tagsApi } from "engine/api";
import { routes } from "engine/routes";
import { push } from "redux-first-history";
import { ITag } from "./types";

export const getTags = createAsyncThunk<ITag[]>("tags/getAll", async (_, { rejectWithValue }) => {
  try {
    const { data } = await tagsApi.getTags();

    return data;
  } catch (error) {
    // console.log(error);
    return rejectWithValue("error happened");
    // throw error;
  }
});

export const getTagsByCategory = createAsyncThunk<ITag[], string>(
  "tags/getAllByCategories",
  async (categoryName, { rejectWithValue }) => {
    try {
      const { data } = await tagsApi.getTagsByCategory(categoryName);

      return data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue("error happened");
      // throw error;
    }
  },
);

export const getTagById = createAsyncThunk<ITag, string>(
  "tags/getTagById",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await tagsApi.getTagById(id);

      return data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue("error happened");
      // throw error;
    }
  },
);

export const addTag = createAsyncThunk<any, ITag>(
  "tags/addTag",
  async (tag, { rejectWithValue, dispatch }) => {
    try {
      const response = await tagsApi.addTag(tag);

      if (response.status === 200) {
        message.success("Tag created successfully");
        dispatch(push(routes.dashboard.tags().overview));
      }

      return {};
    } catch (error) {
      // console.log(error);
      return rejectWithValue("error happened");
      // throw error;
    }
  },
);

export const editTag = createAsyncThunk<any, ITag>(
  "tags/editTag",
  async (tag, { rejectWithValue }) => {
    try {
      const response = await tagsApi.editTag(tag);

      if (response.status === 200) {
        message.success("Tag updated successfully");
      }

      return {};
    } catch (error) {
      // console.log(error);
      return rejectWithValue("error happened");
      // throw error;
    }
  },
);

export const deleteTag = createAsyncThunk<any, string>(
  "tags/deleteTag",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await tagsApi.deleteTag(id);

      if (response.status === 200) {
        message.success("Tag removed successfully");
        dispatch(push(routes.dashboard.tags().overview));
      }

      return {};
    } catch (error) {
      // console.log(error);
      return rejectWithValue("error happened");
      // throw error;
    }
  },
);
