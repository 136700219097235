import { SVGProps } from "react";

export const Other = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <g>
        <g>
          <g>
            <circle fill="currentColor" cx="418.47" cy="256" r="50.03" />
          </g>
          <g>
            <circle fill="currentColor" cx="256" cy="256" r="50.03" />
          </g>
          <g>
            <circle fill="currentColor" cx="93.53" cy="256" r="50.03" />
          </g>
        </g>
      </g>
    </svg>
  );
};
