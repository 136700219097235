import { createSlice } from "@reduxjs/toolkit";
import {
  getArticles,
  getArticleById,
  addArticle,
  editArticle,
  deleteArticle,
  getFilteredArticles,
  getArticlesByIds,
} from "./actions";
import { IArticle, ILoading } from "./types";

interface IArticlesState {
  loading: ILoading;
  articles: IArticle[];
  selectedArticle?: IArticle;
}

const initialState: IArticlesState = {
  loading: {
    getArticles: false,
    getArticleById: false,
    addArticle: false,
    editArticle: false,
    deleteArticle: false,
    getFilteredArticles: false,
    getArticlesByIds: false,
  },
  articles: [],
};

export const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    clearArticleState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getArticles.pending, (state) => {
      state.loading.getArticles = true;
    });
    builder.addCase(getArticles.rejected, (state) => {
      state.loading.getArticles = false;
    });
    builder.addCase(getArticles.fulfilled, (state, action) => {
      state.loading.getArticles = false;
      state.articles = action.payload;
    });
    builder.addCase(getFilteredArticles.pending, (state) => {
      state.loading.getFilteredArticles = true;
    });
    builder.addCase(getFilteredArticles.rejected, (state) => {
      state.loading.getFilteredArticles = false;
    });
    builder.addCase(getFilteredArticles.fulfilled, (state, action) => {
      state.loading.getFilteredArticles = false;
      state.articles = action.payload;
    });
    builder.addCase(getArticlesByIds.pending, (state) => {
      state.loading.getArticlesByIds = true;
    });
    builder.addCase(getArticlesByIds.rejected, (state) => {
      state.articles = [];
      state.loading.getArticlesByIds = false;
    });
    builder.addCase(getArticlesByIds.fulfilled, (state, action) => {
      state.loading.getArticlesByIds = false;
      state.articles = action.payload;
    });
    builder.addCase(getArticleById.pending, (state) => {
      state.loading.getArticleById = true;
    });
    builder.addCase(getArticleById.rejected, (state) => {
      state.loading.getArticleById = false;
    });
    builder.addCase(getArticleById.fulfilled, (state, action) => {
      state.loading.getArticleById = false;
      state.selectedArticle = action.payload;
    });
    builder.addCase(addArticle.pending, (state) => {
      state.loading.addArticle = true;
    });
    builder.addCase(addArticle.rejected, (state) => {
      state.loading.addArticle = false;
    });
    builder.addCase(addArticle.fulfilled, (state) => {
      state.loading.addArticle = false;
    });
    builder.addCase(editArticle.pending, (state) => {
      state.loading.editArticle = true;
    });
    builder.addCase(editArticle.rejected, (state) => {
      state.loading.editArticle = false;
    });
    builder.addCase(editArticle.fulfilled, (state) => {
      state.loading.editArticle = false;
    });
    builder.addCase(deleteArticle.pending, (state) => {
      state.loading.deleteArticle = true;
    });
    builder.addCase(deleteArticle.rejected, (state) => {
      state.loading.deleteArticle = false;
    });
    builder.addCase(deleteArticle.fulfilled, (state) => {
      state.loading.deleteArticle = false;
    });
  },
});

export const { clearArticleState } = articlesSlice.actions;
