import styled from "@emotion/styled";

export const StyledFooter = styled.footer<{ direction: "rtl" | "ltr" }>`
  direction: ${({ direction }) => direction};

  .container {
    flex-direction: row;
    justify-content: center;
    padding: 0.875rem 0;

    .ant-typography {
      font-weight: 500;
      color: ${({ theme }) => theme.palette.text.secondary};
      font-size: 1rem;
    }

    .footer-list {
      display: flex;
      gap: 1.36rem;
      margin-bottom: 0;
      padding-left: 1.36rem;

      li {
        &::marker {
          color: ${({ theme }) => theme.palette.text.secondary};
        }

        a {
          font-size: 1rem;
          font-weight: 500;
          color: ${({ theme }) => theme.palette.text.secondary};
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    .container {
      justify-content: center;

      .ant-typography {
        font-size: 0.875rem;
        font-weight: 400;
      }

      .footer-list {
        li {
          a {
            font-weight: 400;
            font-size: 0.875rem;
          }
        }
      }
    }
  }
`;
