import { css, Global } from "@emotion/react";
import { FC } from "react";
import { ITheme } from "./types";

interface ICssBaseline {
  theme: ITheme;
}

export const CssBaseline: FC<ICssBaseline> = ({ theme }) => {
  return (
    <Global
      styles={css`
        html {
          box-sizing: border-box;
          color-scheme: ${theme.palette.mode};
        }

        *,
        *::before,
        *::after {
          box-sizing: inherit;
          margin: 0;
        }

        body {
          margin: 0;
          background-color: ${theme.palette.background.default};
          color: ${theme.palette.text.primary};
          height: 100vh;
        }
      `}
    />
  );
};
