export const routes = {
  main: {
    base: "/",
    languageDetection: "/language-detection",
    chooseLanguage: "/choose-language",
    aboutUs: "/about-us",
    articles: "/articles",
    articleDetail: (id: string) => `/articles/${id}`,
    privacyPolicy: "/privacy-policy",
    savedArticles: "/saved-articles",
  },
  dashboard: {
    articles: (id?: string) => {
      const base = "dashboard/articles";

      return {
        overview: `/${base}`,
        new: `/${base}/new`,
        detail: `/${base}/${id}`,
      };
    },
    tags: (id?: string) => {
      const base = "dashboard/tags";

      return {
        overview: `/${base}`,
        new: `/${base}/new`,
        detail: `/${base}/${id}`,
      };
    },
  },
};
