import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import { Container, Section } from "components/styled";
import { routes } from "engine/routes";
import { StyledFooter } from "./styled";

export const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <StyledFooter direction={i18n.language === "ar" || i18n.language === "fa" ? "rtl" : "ltr"}>
      <Section background="linear-gradient(to right, #ededed, #ffffff)">
        <Container className="container">
          <a href="https://salmaa.ch" rel="noreferrer" target="_blank">
            <Typography.Text>{t("main.salmaa2022")}</Typography.Text>
          </a>
          <ul className="footer-list">
            <li>
              <Link to={routes.main.privacyPolicy}>{t("main.privacyPolicy")}</Link>
            </li>
            <li>
              <Typography.Link href="mailto:info@reminfo.ch">{t("main.contactUs")}</Typography.Link>
            </li>
          </ul>
        </Container>
      </Section>
    </StyledFooter>
  );
};
