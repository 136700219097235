import styled from "@emotion/styled";
import { Layout } from "antd";

export const StyledHeader = styled(Layout.Header)`
  position: sticky;
  top: 0;
  width: 100%;
  height: 50px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.custom.guyabano};
  border-bottom: 1px solid ${({ theme }) => theme.palette.custom.gray90};

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 40px;
    }
  }

  .ant-space {
    align-items: center;
    gap: 0 !important;

    &-item {
      height: 100%;
      display: flex;
      border-right: 1px solid ${({ theme }) => theme.palette.custom.gray90};
      /* border-left: 1px solid ${({ theme }) => theme.palette.custom.gray90}; */

      &:first-of-type {
        border-left: 1px solid ${({ theme }) => theme.palette.custom.gray90};
      }

      .ant-btn {
        background-color: ${({ theme }) => theme.palette.custom.guyabano};
        height: 100%;
        border: 0;
      }

      .lang {
        .ant-select-selector {
          height: 100%;
          align-items: center;
          border: 0;
          background-color: ${({ theme }) => theme.palette.custom.guyabano};

          .ant-select-selection-item {
            padding: 0 0.5rem !important;
          }
        }
      }
    }
  }
`;
