import { useTranslation } from "react-i18next";

export const useValidators = () => {
  const { t } = useTranslation();

  const required = {
    required: true,
    message: t("dashboard.form.required"),
  };

  return {
    required,
  };
};
