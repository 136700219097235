import { SVGProps } from "react";

export const Work = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="SL/icons/icon/work" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="352228_work_icon"
          transform="translate(1.000000, 1.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <path
            d="M25.2,5.69147005 L19.6,5.69147005 L19.6,2.84573503 C19.6,1.27346642 18.347,0 16.8,0 L11.2,0 C9.653,0 8.4,1.27346642 8.4,2.84573503 L8.4,5.69147005 L2.8,5.69147005 C1.253,5.69147005 0.014,6.96493648 0.014,8.53720508 L0,24.1887477 C0,25.7610163 1.253,27.0344828 2.8,27.0344828 L25.2,27.0344828 C26.747,27.0344828 28,25.7610163 28,24.1887477 L28,8.53720508 C28,6.96493648 26.747,5.69147005 25.2,5.69147005 Z M16.4137931,5.79310345 L10.6206897,5.79310345 L10.6206897,2.89655172 L16.4137931,2.89655172 L16.4137931,5.79310345 Z"
            id="Shape"
          />
        </g>
      </g>
    </svg>
  );
};
