import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Layout } from "antd";
import { useAppDispatch, useAppSelector } from "engine/store";
import { getMe } from "engine/store/auth/actions";
import { useTranslation } from "react-i18next";
import { Header, Menu } from "./components";
import { StyledContent } from "./styled";

export const DashboardLayout = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("admin_token");
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else if (!user) {
      dispatch(getMe());
    }
  }, [token, navigate, user, dispatch]);

  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);

  return (
    <Layout>
      <Header />
      <StyledContent>
        <Menu />
        <div className="main-content">
          <Outlet />
        </div>
      </StyledContent>
    </Layout>
  );
};
