import { useState } from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
  To,
  Link,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Menu, MenuProps, Select, Typography } from "antd";
import { DownOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { routes } from "engine/routes";
import { useLocalStorage, useMediaQuery } from "engine/hooks";
import { Bookmark, World, Menu as MenuIcon, Search } from "components/icons";
import { articleCategories, languages } from "helpers/constants";
import logo from "assets/images/logo.png";
import logoWhite from "assets/images/logo-white.png";
import { StyledHeader, StyledDrawer } from "./styled";

export const Header = () => {
  const { t, i18n } = useTranslation();
  const [, setChoosenLanguage] = useLocalStorage("choosenLanguage", i18n.language);
  const matches = useMediaQuery("(max-width: 576px)");
  const [showDrawer, setShowDrawer] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const onLinkClick = (to: To) => {
    setShowDrawer(false);
    navigate(to);
  };

  const leftMenuItems: MenuProps["items"] = [
    {
      label: (
        <>
          {t("main.articles")} <DownOutlined />
        </>
      ),
      key: "categories",
      popupClassName: "categories-menu",
      children: articleCategories.map((category) => ({
        label: (
          <Link
            to={{
              pathname: routes.main.articles,
              search: `?${createSearchParams({ category: category.name })}`,
            }}
          >
            {t(`main.articleCategories.${category.name}.title`)}
          </Link>
        ),
        key: category.name,
      })),
    },
    {
      label: <Link to={routes.main.aboutUs}>{t("main.aboutUs")}</Link>,
      key: "about-us",
    },
  ];

  const rightMenuItems: MenuProps["items"] = [
    {
      label: (
        <Link to={routes.main.savedArticles} className="saved-articles">
          <Bookmark />
          {t("main.savedArticles")}
        </Link>
      ),
      key: "saved-articles",
    },
    {
      label: (
        <Select
          value={i18n.language}
          onChange={(value) => {
            setChoosenLanguage(value);
            i18n.changeLanguage(value);
          }}
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
        >
          {languages.map((language) => (
            <Select.Option key={language.name} value={language.name}>
              <World /> {language.translatedTitle}
            </Select.Option>
          ))}
        </Select>
      ),
      key: "languages",
    },
  ];

  const drawerMenuItems: MenuProps["items"] = [
    {
      label: (
        <>
          <span className="icon">
            <Search />
          </span>
          {t("main.articles")}
        </>
      ),
      key: "categories",
      children: articleCategories.map((category) => ({
        label: (
          <Typography.Link
            onClick={() =>
              onLinkClick({
                pathname: routes.main.articles,
                search: `?${createSearchParams({ category: category.name })}`,
              })
            }
          >
            {t(`main.articleCategories.${category.name}.title`)}
          </Typography.Link>
        ),
        key: category.name,
      })),
    },
    {
      label: (
        <Typography.Link
          onClick={() =>
            onLinkClick({
              pathname: routes.main.aboutUs,
            })
          }
        >
          <InfoCircleOutlined className="icon" />
          {t("main.aboutUs")}
        </Typography.Link>
      ),
      key: "about-us",
    },
    {
      label: (
        <Typography.Link
          onClick={() =>
            onLinkClick({
              pathname: routes.main.savedArticles,
            })
          }
          className="saved-articles"
        >
          <span className="icon">
            <Bookmark />
          </span>
          {t("main.savedArticles")}
        </Typography.Link>
      ),
      key: "saved-articles",
    },
    {
      label: (
        <Typography.Link
          onClick={() =>
            onLinkClick({
              pathname: routes.main.chooseLanguage,
            })
          }
          className="saved-articles"
        >
          <span className="icon">
            <World />
          </span>
          {t("main.changeLanguage")}
        </Typography.Link>
      ),
      key: "languages",
    },
  ];

  return (
    <StyledHeader direction={i18n.language === "ar" || i18n.language === "fa" ? "rtl" : "ltr"}>
      <div className="logo">
        <Link to={routes.main.base}>
          <img src={logo} alt="ReMinfo" />
        </Link>
      </div>
      <Menu
        mode="horizontal"
        items={leftMenuItems}
        className="left-menu"
        selectedKeys={[searchParams.get("category") ?? location.pathname.split("/")[1]]}
      />
      <Menu
        mode="horizontal"
        items={rightMenuItems}
        selectable
        className="right-menu"
        selectedKeys={[searchParams.get("category") ?? location.pathname.split("/")[1]]}
      />
      {matches && (
        <>
          <Button type="text" className="menu-icon" onClick={() => setShowDrawer(true)}>
            <MenuIcon />
          </Button>
          <StyledDrawer
            placement="right"
            onClose={() => setShowDrawer(false)}
            open={showDrawer}
            rootClassName="drawer-root"
            title={
              <Typography.Link
                onClick={() => onLinkClick({ pathname: routes.main.base })}
                className="logo"
              >
                <img src={logoWhite} alt="ReMinfo" />
              </Typography.Link>
            }
          >
            <Menu
              items={drawerMenuItems}
              className="drawer-menu"
              mode="inline"
              defaultOpenKeys={[searchParams.get("category") ? "categories" : ""]}
              selectedKeys={[searchParams.get("category") ?? location.pathname.split("/")[1]]}
            />
          </StyledDrawer>
        </>
      )}
    </StyledHeader>
  );
};
