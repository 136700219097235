import { Suspense } from "react";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { Provider } from "react-redux";
import { ConfigProvider, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { AppRouting } from "engine/app-routing";
import { ThemeProvider } from "themes";
import "translations";
import store, { history } from "engine/store";

ConfigProvider.config({
  theme: {
    primaryColor: "#248BCC",
  },
});

export const App = () => {
  const { i18n } = useTranslation();

  return (
    <Provider store={store}>
      <ConfigProvider direction={i18n.language === "ar" || i18n.language === "fa" ? "rtl" : "ltr"}>
        <ThemeProvider>
          <Suspense fallback={<Spin className="spinner-container" size="large" />}>
            <Router history={history}>
              <AppRouting />
            </Router>
          </Suspense>
        </ThemeProvider>
      </ConfigProvider>
    </Provider>
  );
};
