import { lazy } from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import { MainLayout, DashboardLayout } from "layouts";

const Home = lazy(() => import("pages").then((module) => ({ default: module.Home })));

const ArticlesOverview = lazy(() =>
  import("pages").then((module) => ({ default: module.ArticlesOverview })),
);

const ArticleDetail = lazy(() =>
  import("pages").then((module) => ({ default: module.ArticleDetail })),
);

const AboutUs = lazy(() => import("pages").then((module) => ({ default: module.AboutUs })));

const SavedArticles = lazy(() =>
  import("pages").then((module) => ({ default: module.SavedArticles })),
);

const PrivacyPolicy = lazy(() =>
  import("pages").then((module) => ({ default: module.PrivacyPolicy })),
);

const LanguageDetection = lazy(() =>
  import("pages").then((module) => ({ default: module.LanguageDetection })),
);

const ChooseLanguage = lazy(() =>
  import("pages").then((module) => ({ default: module.ChooseLanguage })),
);

// dashboard
const Login = lazy(() =>
  import("pages/dashboard").then((module) => ({ default: module.LoginPage })),
);

const DashboardArticlesOverview = lazy(() =>
  import("pages/dashboard/articles").then((module) => ({ default: module.ArticlesOverviewPage })),
);

const DashboardArticleDetail = lazy(() =>
  import("pages/dashboard/articles").then((module) => ({ default: module.ArticleDetailPage })),
);

const TagsOverview = lazy(() =>
  import("pages/dashboard/tags").then((module) => ({ default: module.TagsOverviewPage })),
);

const TagDetail = lazy(() =>
  import("pages/dashboard/tags").then((module) => ({ default: module.TagDetailPage })),
);

export const AppRouting = () => {
  const routesConfig: RouteObject[] = [
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/articles", element: <ArticlesOverview /> },
        { path: "/articles/:id", element: <ArticleDetail /> },
        { path: "/about-us", element: <AboutUs /> },
        { path: "/saved-articles", element: <SavedArticles /> },
        { path: "/privacy-policy", element: <PrivacyPolicy /> },
      ],
    },
    {
      path: "language-detection",
      element: <LanguageDetection />,
    },
    {
      path: "choose-language",
      element: <ChooseLanguage />,
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "dashboard",
      element: <DashboardLayout />,
      children: [
        { index: true, element: <Navigate to="articles" replace /> },
        { path: "articles", element: <DashboardArticlesOverview /> },
        { path: "articles/new", element: <DashboardArticleDetail /> },
        { path: "articles/:id", element: <DashboardArticleDetail /> },
        { path: "tags", element: <TagsOverview /> },
        { path: "tags/new", element: <TagDetail /> },
        { path: "tags/:id", element: <TagDetail /> },
      ],
    },
  ];

  return useRoutes(routesConfig);
};
