import { SVGProps } from "react";

export const Activities = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 512 512" {...props} fill="currentColor">
      <g data-name="/ OUTLINE" id="_OUTLINE">
        <path d="M415.14941,98.36963A223.475,223.475,0,0,0,347.2627,51.37549l-6.5254,14.60937a207.55263,207.55263,0,0,1,63.04493,43.64453Z" />
        <path d="M171.2627,65.98486l-6.5254-14.60937A225.163,225.163,0,0,0,44.74805,181.334L59.834,186.666A209.08546,209.08546,0,0,1,171.2627,65.98486Z" />
        <path d="M109.62988,403.78271A209.26491,209.26491,0,0,1,59.834,325.334l-15.08593,5.332A225.3589,225.3589,0,0,0,98.37012,415.1499Z" />
        <path d="M417.60645,386.96,430.03027,397.041a225.05732,225.05732,0,0,0,22.33887-33.1836l-14.01758-7.71484A208.94865,208.94865,0,0,1,417.60645,386.96Z" />
        <path d="M274.45312,67.75244,248.875,50.70068V50.7002A16.00028,16.00028,0,0,0,224,64.01367V98.11719a15.99444,15.99444,0,0,0,24.875,13.3125l25.57812-17.05127a16.00009,16.00009,0,0,0,0-26.626ZM240,98.11719l-.042-34.12842a.29914.29914,0,0,1,.042.0249v-.00049l25.57812,17.05225Z" />
        <path d="M336,256a64,64,0,1,0-64,64A64.07239,64.07239,0,0,0,336,256Zm-64,48a48,48,0,1,1,48-48A48.05405,48.05405,0,0,1,272,304Z" />
        <polygon points="264 252.687 242.343 274.343 253.657 285.657 280 259.313 280 224 264 224 264 252.687" />
        <path d="M80,288v-8h40v8a24.02718,24.02718,0,0,0,24,24h16a24.02718,24.02718,0,0,0,24-24V224a24.02718,24.02718,0,0,0-24-24H144a24.02718,24.02718,0,0,0-24,24v8H80v-8a24.02718,24.02718,0,0,0-24-24H40a24.02718,24.02718,0,0,0-24,24v64a24.02718,24.02718,0,0,0,24,24H56A24.02718,24.02718,0,0,0,80,288Zm56-64a8.00917,8.00917,0,0,1,8-8h16a8.00917,8.00917,0,0,1,8,8v64a8.00917,8.00917,0,0,1-8,8H144a8.00917,8.00917,0,0,1-8-8ZM80,248h40v16H80ZM32,288V224a8.00917,8.00917,0,0,1,8-8H56a8.00917,8.00917,0,0,1,8,8v64a8.00917,8.00917,0,0,1-8,8H40A8.00917,8.00917,0,0,1,32,288Z" />
        <path d="M96,432a24,24,0,0,0,0,48h72v-.02795A39.96343,39.96343,0,0,0,200,496H312a40.04552,40.04552,0,0,0,40-40V432Zm0,32a8,8,0,0,1,0-16h64v8a40.037,40.037,0,0,0,.80493,8Zm240-8a24.0275,24.0275,0,0,1-24,24H200a24.0275,24.0275,0,0,1-24-24v-8H336Z" />
        <rect height="16" width="16" x="248" y="360" />
        <rect height="16" width="16" x="160" y="352" />
        <rect height="16" width="16" x="168" y="400" />
        <rect height="16" width="16" x="192" y="368" />
        <rect height="16" width="16" x="216" y="392" />
        <rect height="16" width="16" x="216" y="344" />
        <rect height="16" width="16" x="264" y="400" />
        <path d="M432,247.50293A65.42965,65.42965,0,0,0,470.62793,228.686l6.05859-6.0586A65.51183,65.51183,0,0,0,496,176v-8h-8a65.51024,65.51024,0,0,0-46.62793,19.314l-6.05859,6.0586A66.19,66.19,0,0,0,424,208.47791a66.19,66.19,0,0,0-11.31348-15.10535l-6.05859-6.0586A65.51024,65.51024,0,0,0,360,168h-8v8a65.51183,65.51183,0,0,0,19.31348,46.62744l6.05859,6.0586A65.42965,65.42965,0,0,0,416,247.50293V264H368v16h8.9375l5.37207,42.97656A24.03946,24.03946,0,0,0,406.125,344h35.75a24.03946,24.03946,0,0,0,23.81543-21.02344L471.0625,280H480V264H432V247.50293ZM446.62793,204.686l6.05859-6.0586a49.576,49.576,0,0,1,26.55274-13.86621A49.58107,49.58107,0,0,1,465.37207,211.314l-6.05859,6.0586a49.576,49.576,0,0,1-26.55274,13.86621A49.58107,49.58107,0,0,1,446.62793,204.686Zm-57.94141,12.68652-6.05859-6.0586a49.58107,49.58107,0,0,1-13.86719-26.55273,49.576,49.576,0,0,1,26.55274,13.86621l6.05859,6.0586a49.58107,49.58107,0,0,1,13.86719,26.55273A49.576,49.576,0,0,1,388.68652,217.37256ZM454.9375,280l-5.124,40.99219A8.01243,8.01243,0,0,1,441.875,328h-35.75a8.01243,8.01243,0,0,1-7.93848-7.00781L393.0625,280Z" />
        <rect
          height="16.00003"
          transform="translate(162.08411 465.40548) rotate(-66.03822)"
          width="43.77253"
          x="417.22507"
          y="99.99998"
        />
        <rect
          height="16.00003"
          transform="translate(110.25497 473.65826) rotate(-66.03686)"
          width="17.50917"
          x="410.80108"
          y="143.99998"
        />
        <path d="M168,152a24.0275,24.0275,0,0,0,24,24H320a24.0275,24.0275,0,0,0,24-24V128H328V40a24.02718,24.02718,0,0,0-24-24H208a24.02718,24.02718,0,0,0-24,24v88H168ZM200,40a8.00917,8.00917,0,0,1,8-8h96a8.00917,8.00917,0,0,1,8,8v88H200ZM184,144H328v8a8.00917,8.00917,0,0,1-8,8H192a8.00917,8.00917,0,0,1-8-8Z" />
        <path d="M376,372.68652V358.62744a23.84254,23.84254,0,0,0-7.0293-16.9707l-16-16a24.02784,24.02784,0,0,0-33.9414,0l-25.373,25.373a24.02666,24.02666,0,0,0,0,33.94042l16,16A23.84385,23.84385,0,0,0,326.627,408h14.05957l65.65625,65.65674a24.97075,24.97075,0,0,0,35.31446-35.31348Zm54.34277,89.65674a8.9696,8.9696,0,0,1-12.68554,0L347.31348,392H326.627a7.94524,7.94524,0,0,1-5.65625-2.34326l-16-16a8.00889,8.00889,0,0,1,0-11.31348l25.373-25.373a8.00772,8.00772,0,0,1,11.3125,0l16,16A7.94677,7.94677,0,0,1,360,358.62744v20.686l70.34277,70.34326A8.98022,8.98022,0,0,1,430.34277,462.34326Z" />
        <rect
          height="16.00003"
          transform="translate(185.83902 501.95815) rotate(-66.03822)"
          width="43.77253"
          x="457.22507"
          y="99.99998"
        />
        <rect
          height="16.00003"
          transform="translate(134.00902 510.21054) rotate(-66.03686)"
          width="17.50917"
          x="450.80108"
          y="143.99998"
        />
      </g>
    </svg>
  );
};
