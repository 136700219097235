import styled from "@emotion/styled";

export const Container = styled.div`
  width: 790px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 820px) and (min-width: 577px) {
    width: 540px;
  }

  @media screen and (max-width: 576px) {
    width: 100%;
    padding: 0 0.625rem;
  }
`;
