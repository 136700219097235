import styled from "@emotion/styled";
import { Menu } from "antd";

export const StyledMenu = styled(Menu)`
  position: sticky;
  top: 50px;
  z-index: 1;
  padding-top: 1rem;
  padding-bottom: 1rem;
  gap: 0.5rem;
  width: 100%;
  flex: 1;
  background-color: ${({ theme }) => theme.palette.custom.white};

  .ant-menu-item {
    top: unset;
    margin-top: 0 !important;
    display: flex;
    line-height: 2.5;
    padding: 0 !important;

    a {
      display: block;
      border-radius: 0.375rem;
      padding: 0 1.5rem;

      &:before {
        content: none !important;
      }
    }

    &-selected,
    &:hover {
      a {
        background-color: ${({ theme }) => theme.palette.primary.main};
        color: ${({ theme }) => theme.palette.custom.white};
      }
    }

    &:after {
      content: none !important;
    }
  }
`;
