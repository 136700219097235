// import { coreTheme } from "./core";
import { ITheme } from "./types";

export const light: ITheme = {
  palette: {
    mode: "light",
    text: {
      primary: "#000000",
      secondary: "#999999",
      disabled: "",
    },
    primary: {
      main: "#248BCC",
      dark: "#3376FD",
    },
    background: {
      default: "#ffffff",
    },
    custom: {
      white: "#ffffff",
      cadetBlue: "#04A3AF",
      cyanBlue: "#0074c2",
      cyanBlueDark: "#001726",
      gray90: "#E5E5E5",
      guyabano: "#f8f8f8",
      toledo: "#2e333c",
    },
  },
  // ...coreTheme,
};
