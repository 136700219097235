import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Menu, Space } from "antd";
import { useAppSelector } from "engine/store";
import logo from "assets/images/logo.png";
import { StyledHeader } from "./styled";

export const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);

  const handleLogout = () => {
    localStorage.removeItem("admin_token");
    navigate("/login");
  };

  return (
    <StyledHeader>
      <div className="logo">
        <Link to="/dashboard/articles">
          <img src={logo} alt="ReMinfo" />
        </Link>
      </div>
      <Space>
        {user && (
          <Dropdown
            overlay={
              <Menu
                items={[
                  {
                    key: "1",
                    label: (
                      <Button onClick={handleLogout} type="text">
                        {t("dashboard.logout")}
                      </Button>
                    ),
                  },
                ]}
              />
            }
            placement="bottomRight"
            arrow
          >
            <Button>
              {user.firstname} {user.lastname}
            </Button>
          </Dropdown>
        )}
      </Space>
    </StyledHeader>
  );
};
