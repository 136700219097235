import { createAsyncThunk } from "@reduxjs/toolkit";
import { push } from "redux-first-history";
import { authApi } from "engine/api";
import { ILoginRequest, IUser } from "./types";

export const login = createAsyncThunk<IUser, ILoginRequest>(
  "auth/login",
  async (loginData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authApi.login(loginData);

      if (!data) {
        return rejectWithValue("error happened");
      }

      localStorage.setItem("admin_token", data.token);
      dispatch(push("/dashboard"));

      return data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue("error happened");
      // throw error;
    }
  },
);

export const getMe = createAsyncThunk<IUser>("auth/getMe", async (_, { rejectWithValue }) => {
  try {
    const { data } = await authApi.me();

    if (!data) {
      return rejectWithValue("error happened");
    }

    return data.user;
  } catch (error) {
    // console.log(error);
    return rejectWithValue("error happened");
    // throw error;
  }
});
